import React, {useRef, useState} from "react"
import axios from "axios";
import addToMailchimp from "gatsby-plugin-mailchimp";

const MeldungForm = ({}) => {
    const [valueKontakt, setValueKontakt] = React.useState({})
    let serverResponse = useRef();
    let submitBtn = useRef();

    const [fileName, setFileName] = useState(null);
    const [loading, setLoading] = useState(false);
    const fileInputRef = useRef(null);

    function handleChange(e) {
        valueKontakt[e.target.id] = e.target.value
        serverResponse.current.style.color = "#000000";
        serverResponse.current.innerText = "";
        setValueKontakt({...valueKontakt})
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const allowedTypes = [
            // Images
            'image/jpeg',        // JPG
            'image/png',         // PNG
            'image/gif',         // GIF
            'image/bmp',         // BMP
            'image/webp',        // WEBP
            'image/svg+xml',     // SVG

            // Videos
            'video/mp4',         // MP4
            'video/x-msvideo',   // AVI
            'video/mpeg',        // MPEG
            'video/webm',        // WebM
            'video/ogg',         // OGG
            'video/quicktime',   // MOV

            // Audio
            'audio/mpeg',        // MP3
            'audio/wav',         // WAV
            'audio/ogg',         // OGG
            'audio/aac',         // AAC
            'audio/flac',        // FLAC

            // Documents
            'application/pdf',   // PDF
            'application/msword', // DOC
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // DOCX
            'application/vnd.ms-excel', // XLS
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // XLSX
            'application/vnd.ms-powerpoint', // PPT
            'application/vnd.openxmlformats-officedocument.presentationml.presentation', // PPTX
            'text/plain',        // TXT
            'application/rtf',   // RTF
            'application/vnd.oasis.opendocument.text', // ODT
            'application/vnd.oasis.opendocument.spreadsheet', // ODS

            // Archives
            'application/zip',   // ZIP
            'application/x-tar', // TAR
            'application/x-rar-compressed', // RAR
            'application/gzip',  // GZIP
            'application/x-7z-compressed', // 7Z

            // Other
            'application/json',  // JSON
            'application/xml',   // XML
            'application/javascript', // JS
            'text/css',          // CSS
            'text/html',         // HTML
        ];
        const maxSizeInBytes = 40 * 1024 * 1024;
        if (allowedTypes.includes(file.type)) {
            if (file.size <= maxSizeInBytes) {
                setFileName(file.name);
            } else {
                event.target.value = '';
                serverResponse.current.style.color = "#F05353";
                serverResponse.current.innerText = "Maximal 40MB erlaubt.";
            }
        } else {
            serverResponse.current.style.color = "#F05353";
            serverResponse.current.innerText = "Dieser Dateityp ist nicht erlaubt.";
            event.target.value = '';
        }
    };

    const handleRemoveFile = () => {
        setFileName(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
    };

    async function onSubmitKontakt(e) {
        e.preventDefault()
        if (valueKontakt.kontaktvorname && valueKontakt.kontaktnachname && valueKontakt.kontaktemail && valueKontakt.kontaktstrasse && valueKontakt.kontaktnr && valueKontakt.kontaktort && valueKontakt.kontaktplz && valueKontakt.kontakttelefonnummer && valueKontakt.kontaktbetreff && valueKontakt.kontaktauftragsnr) {
            serverResponse.current.style.color = "#000000";
            serverResponse.current.innerText = "";

            submitBtn.current.disabled = true;
            setLoading(true)

            var newsletterCheckbox = document.querySelector('#kontaktnewsletter');
            if (newsletterCheckbox.checked) {
                console.log('Der Benutzer hat den Newsletter abonniert.');
                addToMailchimp(valueKontakt.kontaktemail)
                    .then(data => {
                        console.log('Mailchimp Success:')
                        console.log(data)
                    })
                    .catch(() => {
                        console.error('Error adding to Mailchimp')
                    })
            }

            let name = valueKontakt.kontaktvorname + " " + valueKontakt.kontaktnachname;
            let anschrift = valueKontakt.kontaktstrasse + " " + valueKontakt.kontaktnr + ", " + valueKontakt.kontaktplz + " " + valueKontakt.kontaktort;

            const formData = new FormData();
            formData.append('name', name);
            formData.append('email', valueKontakt.kontaktemail);
            formData.append('anschrift', anschrift);
            formData.append('tel', valueKontakt.kontakttelefonnummer);
            formData.append('auftragsnr', valueKontakt.kontaktauftragsnr);
            formData.append('betreff', valueKontakt.kontaktbetreff);
            formData.append('nachricht', valueKontakt.kontaktnachricht ? valueKontakt.kontaktnachricht : 'Keine Nachricht');

            // Add the file if present
            const fileInput = document.querySelector('input[type="file"]');
            if (fileInput.files.length > 0) {
                formData.append('file', fileInput.files[0]);
            }

            axios.post('https://api.eniosol.de/meldung.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(function (response) {
                    if (response.data.status === 'success') {
                        serverResponse.current.style.color = "#64C028";
                        submitBtn.current.disabled = false;
                        setLoading(false)
                        setValueKontakt({});
                        handleRemoveFile();
                        newsletterCheckbox.checked = false;
                        serverResponse.current.innerText = "Nachricht erfolgreich abgesendet!";
                    } else {
                        serverResponse.current.style.color = "#F05353";
                        serverResponse.current.innerText = "Error: " + response.data.message;
                        submitBtn.current.disabled = false;
                        setLoading(false)
                        //serverResponse.current.innerText = "Es gab einen Fehler beim Absenden der Nachricht. Bitte versuchen Sie es nochmal ohne angehängter Datei.";
                    }
                })
                .catch(function (error) {
                    serverResponse.current.style.color = "#F05353";
                    serverResponse.current.innerText = "Es gab einen Fehler beim Absenden der Nachricht.";
                    submitBtn.current.disabled = false;
                    setLoading(false)
                    console.error('Request Error:', error);
                });
        } else {
            serverResponse.current.innerText = "Bitte fülle alle Pflichtfelder aus!";
            serverResponse.current.style.color = "#F05353";
        }
    }

    return <form id={"kontakt"} onSubmit={onSubmitKontakt} method="post" action="/api/form.php" className={""}>
        <div className="tablet:flex mt-doublebase">
            <div className={"flex flex-col w-full laptop:w-[40%] mr-base"}>
                <label htmlFor="kontaktvorname" className={"bold phonexl:pl-halfbase mb-2"}>Vorname*</label>
                <input name={"kontaktvorname"} id="kontaktvorname" value={valueKontakt[`kontaktvorname`] || ``} onChange={handleChange} type="text" placeholder={"Bitte eingeben"} className={"border-[2px] phonexl:border rounded-[15px] border-[#707070] py-threequarterbase px-halfbase focus:outline-orange focus:outline-2 focus:outline focus:border-orange"}/>
            </div>
            <div className={"flex flex-col  w-full laptop:w-[40%] mt-base tablet:mt-0"}>
                <label htmlFor="kontaktnachname" className={"bold phonexl:pl-halfbase mb-2"}>Nachname*</label>
                <input name={"kontaktnachname"} id="kontaktnachname" value={valueKontakt[`kontaktnachname`] || ``} onChange={handleChange} type="text" placeholder={"Bitte eingeben"} className={"border-[2px] phonexl:border rounded-[15px] border-[#707070] py-threequarterbase px-halfbase focus:outline-orange focus:outline-2 focus:outline focus:border-orange"}/>
            </div>
        </div>
        <div className="tablet:flex mt-base">
            <div className={"flex flex-col  w-full laptop:w-[40%]"}>
                <label htmlFor="kontakttelefonnummer" className={"bold phonexl:pl-halfbase mb-2"}>Telefonnummer. Hier
                    sind Sie erreichbar.*</label>
                <input name={"kontakttelefonnummer"} id="kontakttelefonnummer" value={valueKontakt[`kontakttelefonnummer`] || ``} onChange={handleChange} type="text" placeholder={"Bitte eingeben"} className={"border-[2px] phonexl:border rounded-[15px] border-[#707070] py-threequarterbase px-halfbase focus:outline-orange focus:outline-2 focus:outline focus:border-orange"}/>
            </div>
        </div>
        <div className="tablet:flex mt-base">
            <div className={"flex flex-col  w-full laptop:w-[40%] mr-base"}>
                <label htmlFor="kontaktstrasse" className={"bold phonexl:pl-halfbase mb-2"}>Straße*</label>
                <input name={"kontaktstrasse"} id="kontaktstrasse" value={valueKontakt[`kontaktstrasse`] || ``} onChange={handleChange} type="text" placeholder={"Bitte eingeben"} className={"border-[2px] phonexl:border rounded-[15px] border-[#707070] py-threequarterbase px-halfbase focus:outline-orange focus:outline-2 focus:outline focus:border-orange"}/>
            </div>
            <div className={"flex justify-between  w-full laptop:w-[40%] mt-base tablet:mt-0"}>
                <div className={"flex flex-col w-[calc(50%-0.5rem)]"}>
                    <label htmlFor="kontaktnr" className={"bold phonexl:pl-halfbase mb-2"}>Hausnummer*</label>
                    <input name={"kontaktnr"} id="kontaktnr" value={valueKontakt[`kontaktnr`] || ``} onChange={handleChange} type="text" placeholder={"Bitte eingeben"} className={"border-[2px] phonexl:border rounded-[15px] border-[#707070] py-threequarterbase px-halfbase focus:outline-orange focus:outline-2 focus:outline focus:border-orange"}/>
                </div>
                <div className={"flex flex-col w-[calc(50%-0.5rem)]"}>
                    <label htmlFor="kontaktplz" className={"bold phonexl:pl-halfbase mb-2"}>PLZ*</label>
                    <input name={"kontaktplz"} id="kontaktplz" value={valueKontakt[`kontaktplz`] || ``} onChange={handleChange} type="text" placeholder={"Bitte eingeben"} className={"border-[2px] phonexl:border rounded-[15px] border-[#707070] py-threequarterbase px-halfbase focus:outline-orange focus:outline-2 focus:outline focus:border-orange"}/>
                </div>
            </div>
        </div>
        <div className="tablet:flex mt-base">
            <div className={"flex flex-col  w-full laptop:w-[40%] mr-base"}>
                <label htmlFor="kontaktort" className={"bold phonexl:pl-halfbase mb-2"}>Ort*</label>
                <input name={"kontaktort"} id="kontaktort" value={valueKontakt[`kontaktort`] || ``} onChange={handleChange} type="text" placeholder={"Bitte eingeben"} className={"border-[2px] phonexl:border rounded-[15px] border-[#707070] py-threequarterbase px-halfbase focus:outline-orange focus:outline-2 focus:outline focus:border-orange"}/>
            </div>
            <div className={"flex flex-col  w-full laptop:w-[40%] mt-base tablet:mt-0"}>
                <label htmlFor="kontaktemail" className={"bold phonexl:pl-halfbase mb-2"}>E-Mail-Adresse*</label>
                <input name={"kontaktemail"} id="kontaktemail" value={valueKontakt[`kontaktemail`] || ``} onChange={handleChange} type="email" placeholder={"Bitte eingeben"} className={"border-[2px] phonexl:border rounded-[15px] border-[#707070] py-threequarterbase px-halfbase focus:outline-orange focus:outline-2 focus:outline focus:border-orange"}/>
            </div>
        </div>
        <div className="tablet:flex mt-base">
            <div className={"flex flex-col  w-full laptop:w-[40%] mr-base"}>
                <label htmlFor="kontaktauftragsnr" className={"bold phonexl:pl-halfbase mb-2"}>Auftragsnummer*</label>
                <input name={"kontaktauftragsnr"} id="kontaktauftragsnr" value={valueKontakt[`kontaktauftragsnr`] || ``} onChange={handleChange} type="text" placeholder={"Bitte eingeben"} className={"border-[2px] phonexl:border rounded-[15px] border-[#707070] py-threequarterbase px-halfbase focus:outline-orange focus:outline-2 focus:outline focus:border-orange"}/>
            </div>
            <div className={"flex flex-col  w-full laptop:w-[40%] mt-base tablet:mt-0"}>
                <label htmlFor="kontaktbetreff" className={"bold phonexl:pl-halfbase mb-2"}>Betreff*</label>
                <input name={"kontaktbetreff"} id="kontaktbetreff" value={valueKontakt[`kontaktbetreff`] || ``} onChange={handleChange} type="text" placeholder={"Bitte eingeben"} className={"border-[2px] phonexl:border rounded-[15px] border-[#707070] py-threequarterbase px-halfbase focus:outline-orange focus:outline-2 focus:outline focus:border-orange"}/>
            </div>
        </div>
        <div className="flex mt-base">
            <div className={"flex flex-col w-full laptop:w-[calc(80%+2rem)]"}>
                <label htmlFor="kontaktnachricht" className={"bold phonexl:pl-halfbase mb-2"}>Beschreiben Sie uns kurz
                    Ihr Problem:</label>
                <textarea id={"kontaktnachricht"} name={"kontaktnachricht"} value={valueKontakt[`kontaktnachricht`] || ``} onChange={handleChange} placeholder={"Bitte eingeben"} className={"border-[2px] phonexl:border rounded-[15px] border-[#707070] py-threequarterbase px-halfbase focus:outline-orange focus:outline-2 focus:outline focus:border-orange min-h-[200px] resize-none"}/>
            </div>
        </div>
        <div className="relative tablet:flex mt-base items-center">
            <input ref={fileInputRef}
                   onChange={handleFileChange} type="file" name="file" id="file" className="inputfile"/>
            <label htmlFor="file" className={"btn border border-black hover:bg-black hover:text-white landingbtn smalltext bold uppercase phonexl:!px-12 !w-full phonexl:!w-[230px]"}>Datei
                anhängen</label>
            <div className="flex items-center tablet:ml-base mt-base tablet:mt-0">
                <div className="flex items-center bold max-w-md !leading-[1.1] flex-1 relative">
                    {fileName && (<>
                            <div
                                onClick={handleRemoveFile}
                                className="bg-orange border-2 border-white h-[40px] w-[40px] rounded-full relative cursor-pointer"
                            >
                                <div className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 w-[40px] h-[2px] bg-white rotate-45"></div>
                                <div className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 w-[40px] h-[2px] bg-white -rotate-45"></div>
                            </div>
                            <div className={"break-words tablet:break-normal ml-[10px] w-[calc(100%-140px)] tablet:w-unset"}>
                                {fileName}
                            </div>
                        </>)}
                </div>
            </div>
        </div>
        <div className="relative flex flex-col-reverse tablet:flex-row mt-base items-center">
            <button type={"submit"} ref={submitBtn} className="btn border border-black hover:bg-black hover:text-white landingbtn smalltext bold uppercase phonexl:!px-12 !w-full phonexl:!w-[250px]">
                {loading ? <div
                    key="loading"
                    className="text-content absolute"
                >
                    <div className="dot-flashing"></div>
                </div> : 'Absenden'}
            </button>
            <div className="flex w-full tablet:items-center tablet:ml-base mb-doublebase tablet:mb-0">
                <label className="container24radio bold pl-halfbase max-w-md !leading-[1.1] flex-1">
                    Ich würde gerne auf dem Laufenden bleiben und den eniosol-Newsletter abonnieren. Das Abonnement ist
                    jederzeit wieder kündbar.
                    <input name={"kontaktnewsletter"} id={"kontaktnewsletter"} type="checkbox"/>
                    <span className="checkmark24 customradio h-[40px] w-[40px]"></span>
                </label>
            </div>
            <p className="small bold serverresponse mt-base absolute -bottom-[60px]" ref={serverResponse}></p>
        </div>
    </form>
}

export default MeldungForm

