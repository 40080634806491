import React, {useRef, useState} from "react";
import Navbar from "../../components/Navbar";
import JobList from "../../components/JobList";
import {graphql, Link} from "gatsby";

import Marquee from "react-fast-marquee";
import Layout from "../../components/Layout";
import Head from "../../components/Head";
import MyPortableText from "../../components/MyPortableText";
import MyPortableTextMeldung from "../../components/MyPortableTextMeldung";
import MeldungForm from "../../components/meldung/MeldungForm";

export const query = graphql`
    {
    allSanityMeldung{
    edges
    {
    node{
      title
        form_subtitle
        form_title
        laufenden_title
        laufenden_text
        seo{
            seo_title
        }
    }
  }
  }
  allSanitySettings {
    edges {
      node {
        instalink
        fblink
        linkedinlink
      }
    }
  }
}
`


const Formular = ({data}) => {

    const meldung = data?.allSanityMeldung?.edges[0]?.node;

    const serverResponse = useRef();

    return (
        <Layout settings={data.allSanitySettings.edges[0].node}>
            <Head title={meldung?.seo?.seo_title}/>
            <div className="mt-doublebase">
                <Marquee gradient={false} speed={40} className={'mobilemargin'}>
                    <div className="marqueeText text-white bg-orange">
                        <span className="marqueeText__inner uppercase">{meldung?.title}</span>
                        <span className="marqueeText__inner uppercase">{meldung?.title}</span>
                        <span className="marqueeText__inner uppercase">{meldung?.title}</span>
                        <span className="marqueeText__inner uppercase">{meldung?.title}</span>
                        <span className="marqueeText__inner uppercase">{meldung?.title}</span>
                        <span className="marqueeText__inner uppercase">{meldung?.title}</span>
                        <span className="marqueeText__inner uppercase">{meldung?.title}</span>
                        <span className="marqueeText__inner uppercase">{meldung?.title}</span>
                        <span className="marqueeText__inner uppercase">{meldung?.title}</span>
                        <span className="marqueeText__inner uppercase">{meldung?.title}</span>
                        <span className="marqueeText__inner uppercase">{meldung?.title}</span>
                        <span className="marqueeText__inner uppercase">{meldung?.title}</span>
                        <span className="marqueeText__inner uppercase">{meldung?.title}</span>
                        <span className="marqueeText__inner uppercase">{meldung?.title}</span>
                        <span className="marqueeText__inner uppercase">{meldung?.title}</span>
                        <span className="marqueeText__inner uppercase">{meldung?.title}</span>
                        <span className="marqueeText__inner uppercase">{meldung?.title}</span>
                        <span className="marqueeText__inner uppercase">{meldung?.title}</span>
                    </div>
                </Marquee>
            </div>
            <div className={"px-[20px] py-doublebase tablet:px-[80px] w-full"}>
                <div className="">
                    <p className="bold uppercase pp-mori-bold">
                        {meldung?.form_subtitle}
                    </p>
                    <h3 className={"pp-mori text-headlinemobile mobile:text-headline max-w-[400px] leading-[1] mt-base"}>
                        {meldung?.form_title}
                    </h3>
                </div>
                <div>
                    <MeldungForm/>
                </div>
            </div>
            <div className={"w-full bg-black px-[20px] tablet:px-[80px] py-doublebase"}>
                <div className="service__testimonial__container color-whiteonblack laptop:!w-[calc(80%+2rem)] !justify-between !p-0">
                    <div className="service__testi__left">
                        <h3 className={"pp-mori text-white text-headlinemobile mobile:text-headline max-w-[400px] leading-[1]"}>
                            {meldung?.laufenden_title}
                        </h3>
                        <p className="pp-mori text-white text-p max-w-[300px] mt-base">
                            {meldung?.laufenden_text}
                        </p>
                    </div>
                    <div className="service__testi__right arrowbtn__wrapper tablet:!w-fit">
                        <Link to={'/service'} className="btn arrowbtn bluegrey smalltext bold filled">
                            <svg xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 53 53">
                                <g id="Group_430" data-name="Group 430" transform="translate(-1290 -1780)">
                                    <g id="Ellipse_2" data-name="Ellipse 2" transform="translate(1290 1780)" fill="none" stroke="#fff" stroke-width="3">
                                        <circle cx="26.5" cy="26.5" r="26.5" stroke="none"/>
                                        <circle cx="26.5" cy="26.5" r="25" fill="none"/>
                                    </g>
                                    <g id="Group_23" data-name="Group 23" transform="translate(1302.162 1819.429) rotate(-90)">
                                        <g id="Group_13" data-name="Group 13">
                                            <line id="Line_1" data-name="Line 1" y2="26.138" transform="translate(11.59)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                                            <path id="Path_36" data-name="Path 36" d="M0,0,11.59,11.59,23.181,0" transform="translate(0 17.086)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            <p className="small bold">PHOTOVOLTAIK PLANEN</p>
                        </Link>
                        <Link to={'/#energierechner'} className="btn arrowbtn green smalltext bold filled">
                            <svg xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 53 53">
                                <g id="Group_430" data-name="Group 430" transform="translate(-1290 -1780)">
                                    <g id="Ellipse_2" data-name="Ellipse 2" transform="translate(1290 1780)" fill="none" stroke="#fff" stroke-width="3">
                                        <circle cx="26.5" cy="26.5" r="26.5" stroke="none"/>
                                        <circle cx="26.5" cy="26.5" r="25" fill="none"/>
                                    </g>
                                    <g id="Group_23" data-name="Group 23" transform="translate(1302.162 1819.429) rotate(-90)">
                                        <g id="Group_13" data-name="Group 13">
                                            <line id="Line_1" data-name="Line 1" y2="26.138" transform="translate(11.59)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                                            <path id="Path_36" data-name="Path 36" d="M0,0,11.59,11.59,23.181,0" transform="translate(0 17.086)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            <p className="small bold">PERS. STROMPREISE BERECHNEN</p>
                        </Link>
                        <Link to={'/kontakt'} className="btn arrowbtn pink smalltext bold filled">
                            <svg xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 53 53">
                                <g id="Group_430" data-name="Group 430" transform="translate(-1290 -1780)">
                                    <g id="Ellipse_2" data-name="Ellipse 2" transform="translate(1290 1780)" fill="none" stroke="#fff" stroke-width="3">
                                        <circle cx="26.5" cy="26.5" r="26.5" stroke="none"/>
                                        <circle cx="26.5" cy="26.5" r="25" fill="none"/>
                                    </g>
                                    <g id="Group_23" data-name="Group 23" transform="translate(1302.162 1819.429) rotate(-90)">
                                        <g id="Group_13" data-name="Group 13">
                                            <line id="Line_1" data-name="Line 1" y2="26.138" transform="translate(11.59)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                                            <path id="Path_36" data-name="Path 36" d="M0,0,11.59,11.59,23.181,0" transform="translate(0 17.086)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            <p className="small bold">TERMIN VEREINBAREN</p>
                        </Link>
                        <Link to={'/blog'} className="btn arrowbtn orange smalltext bold filled">
                            <svg xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 53 53">
                                <g id="Group_430" data-name="Group 430" transform="translate(-1290 -1780)">
                                    <g id="Ellipse_2" data-name="Ellipse 2" transform="translate(1290 1780)" fill="none" stroke="#fff" stroke-width="3">
                                        <circle cx="26.5" cy="26.5" r="26.5" stroke="none"/>
                                        <circle cx="26.5" cy="26.5" r="25" fill="none"/>
                                    </g>
                                    <g id="Group_23" data-name="Group 23" transform="translate(1302.162 1819.429) rotate(-90)">
                                        <g id="Group_13" data-name="Group 13">
                                            <line id="Line_1" data-name="Line 1" y2="26.138" transform="translate(11.59)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                                            <path id="Path_36" data-name="Path 36" d="M0,0,11.59,11.59,23.181,0" transform="translate(0 17.086)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            <p className="small bold">GRÜNER BLOG / GRÜNES LEBEN</p>
                        </Link>
                        <a href={data?.allSanitySettings?.edges[0]?.node?.instalink} className="btn arrowbtn !bg-white smalltext bold filled">
                            <svg xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 53 53">
                                <g id="Group_430" data-name="Group 430" transform="translate(-1290 -1780)">
                                    <g id="Ellipse_2" data-name="Ellipse 2" transform="translate(1290 1780)" fill="none" stroke="#000" stroke-width="3">
                                        <circle cx="26.5" cy="26.5" r="26.5" stroke="none"/>
                                        <circle cx="26.5" cy="26.5" r="25" fill="none"/>
                                    </g>
                                    <g id="Group_23" data-name="Group 23" transform="translate(1302.162 1819.429) rotate(-90)">
                                        <g id="Group_13" data-name="Group 13">
                                            <line id="Line_1" data-name="Line 1" y2="26.138" transform="translate(11.59)" fill="none" stroke="#000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                                            <path id="Path_36" data-name="Path 36" d="M0,0,11.59,11.59,23.181,0" transform="translate(0 17.086)" fill="none" stroke="#000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            <p className="small bold">INSTAGRAM</p>
                        </a>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Formular;
